import { observable, action, toJS } from 'mobx'
import { persist } from 'mobx-persist'
import api from '../services/ApiDefault'
import routerStore from './RouterStore'
import projectStore from './ProjectStore'
import { showError, showMessage, showResponseError } from '../utilities/utils'
import { saveAs } from 'file-saver'
import moment from 'moment-timezone'
import i18n from '../i18n/i18n'
import _ from 'lodash'
import JSZip from 'jszip'
import uuid from 'uuid'
import Moment from 'moment'
import organizationStore from './OrganizationStore'
export class RecordStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @observable isDownloading = false
  @observable records = []
  @observable record = null
  @observable shareVideoRecord = null
  @observable recordsTrash = []
  @observable deleteRecordId = null
  @observable setTime = null
  @observable paramRecord = {}
  @observable offset = 0
  @observable limit = 20
  @observable changeListRecord = false

  @action setParamRecord (paramRecord) {
    this.paramRecord = paramRecord
  }

  @action setOffset (offset) {
    this.offset = offset
  }

  @action setLimit (limit) {
    this.limit = limit
  }

  @action setDeleteRecordId (id) {
    this.deleteRecordId = id
  }

  @action setRecord (record) {
    this.record = record
  }

  @action setRecords (records) {
    this.records = records
  }

  @action setChangeListRecord (changeListRecord) {
    this.changeListRecord = changeListRecord
  }

  @action async getListRecord (id, isProject, showError = true) {
    const offset = this.offset
    this.isLoading = true
    this.isDownloading = false

    const response = await api.getListRecord(
      isProject
        ? { projectId: id, disable: false, offset, limit: this.limit, ...this.paramRecord }
        : { projectFolderId: id, disable: false, offset, limit: this.limit, ...this.paramRecord },
    )
    // console.log({ projectId: id, disable: false, offset, limit: this.limit, ...this.paramRecord })
    this.isLoading = false
    if (response.ok) {
      if (projectStore.id === id && !this.changeListRecord) {
        if (offset === 0) {
          this.records = response.data
        } else {
          this.records = _.concat(toJS(this.records), response.data)
        }
      }
    } else {
      showError && showResponseError(response)
    }
  }

  @action async getRecord (id, shareId) {
    this.isLoading = true
    this.isDownloading = false
    const response = shareId ? await api.getShareRecord(id, shareId) : await api.getRecord(id)
    this.isLoading = false
    if (response.ok) {
      this.record = response.data
      this.records = this.records.map(r => (r.id === this.record.id ? response.data : r))
      return response.data
    } else {
      showResponseError(response)
    }
  }

  @action async getTrash () {
    this.isLoading = true
    const currentOrganization = organizationStore.currentOrganization
    const response = await api.getListTrashRecord({
      orgId: currentOrganization ? currentOrganization.orgId : null,
    })
    this.isLoading = false
    if (response.ok) {
      this.recordsTrash = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async editRecord (recordId, description) {
    const response = await api.editRecord(recordId, { description })
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('descriptionUpdated'))
      this.getRecord(recordId)
    } else {
      showResponseError(response)
    }
  }

  @action async moveRecordToTrash (recordId) {
    if (this.setTime) {
      clearTimeout(this.setTime)
    }
    this.isLoading = true

    const response = await api.moveRecordToTrash(recordId)

    if (response.ok) {
      this.deleteRecordId = recordId
      routerStore.push('/dashboard')
      this.setTime = setTimeout(() => {
        this.deleteRecordId = null
      }, 10000)
    } else {
      showResponseError(response)
    }
  }

  @action async deletePhotoInSeries (record, index) {
    this.isLoading = true
    const response = await api.deletePhotoInSeries(record.id, record.series[index].id)
    this.isLoading = false
    if (response.ok) {
      return true
    } else {
      showResponseError(response)
      return false
    }
  }

  @action async restoreRecord (id) {
    this.isLoading = true

    const response = await api.restoreRecord(id)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('photoRestored'))
      this.getTrash()
      this.deleteRecordId = null
    } else {
      showResponseError(response)
    }
  }

  @action async deleteRecord (id) {
    this.isLoading = true

    const response = await api.deleteRecord(id)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('photoDeleted'))
      this.getTrash()
    } else {
      showResponseError(response)
    }
  }

  @action async updateRecord (id, data) {
    this.isLoading = true

    const response = await api.updateRecord(id, data)
    this.isLoading = false
    if (response.ok) {
      this.records.map(r => {
        if (r.id === id) {
          r.status = data.status
        }
      })
    } else {
      showResponseError(response)
    }
  }

  @action async emptyTrash (orgId) {
    this.isLoading = true

    const response = await api.emptyTrash(orgId)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('emptyTrashSuccess'))
      this.getTrash()
      projectStore.getProjectsTrash()
      projectStore.getProjectFoldersTrash()
    } else {
      showResponseError(response)
    }
  }

  @action async downloadRecord (record, type, index) {
    // this.isDownloading = true
    // showMessage(i18n.t('theFileIsBeingDownloaded'))
    let response = null
    const seriesId = _.isEmpty(record.series) ? undefined : record.series[index].id
    if (type === 'pdf') {
      response = await api.downloadRecordMeta(record.id, type, seriesId)
    } else if (type === 'photo') {
      response = await api.downloadRecordMeta(record.id, type, seriesId)
    } else {
      response = await api.downloadRecord(record.id, seriesId)
    }

    // this.isDownloading = false
    if (response.ok) {
      const time = moment(record.created)
        .tz('Europe/Stockholm')
        .format('YYYY-MM-DD-HH-ss')
      const names = [record.projectInfo.name]
      if (record.projectFolderInfo) {
        names.push(record.projectFolderInfo.name)
      }
      if (seriesId) {
        names.push(record.series[index].serialNumber)
      } else {
        names.push(record.serial_number)
      }
      if (record.description) {
        names.push(record.description.substring(0, 80))
      }
      names.push(time)
      const replaceItems = { å: 'a', ä: 'a', ö: 'o' }
      const fileName =
        _.compact(names)
          .join('-')
          .replace(/[å,ä,ö]/, m => replaceItems[m]) + (type === 'pdf' ? '.pdf' : '.jpg')
      saveAs(response.data, fileName)
      // showMessage('Restore Record Success')
    } else {
      showResponseError(response)
    }
  }

  @action async moveRecord (recordId, data, name) {
    if (this.setTime) {
      clearTimeout(this.setTime)
    }
    this.isLoading = true

    const response = await api.moveRecord(recordId, data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('photoSMoved', { name }))
      this.getRecord(recordId)
    } else {
      if (response.status === 403) {
        showError(i18n.t('youAreNotAllowedToMoveThisPhoto'))
      } else {
        showResponseError(response)
      }
    }
  }

  @action async copyRecord (id, data, name) {
    this.isLoading = true

    const response = await api.copyRecord(id, data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('photoSCopied', { name }))
    } else {
      showResponseError(response)
    }
  }

  @action async copyListRecord (records, data, name) {
    this.isLoading = true
    const responses = []
    for (const record of records) {
      const response = await api.copyRecord(record.id, data)
      responses.push(response)
    }
    this.isLoading = false
    const responseError = _.find(responses, { ok: false })
    if (!responseError) {
      showMessage(i18n.t('photoSCopied', { count: records.length, name }))
    } else {
      showResponseError(responseError)
    }
  }

  @action async moveListRecord (records, data, name) {
    this.isLoading = true
    const responses = []
    for (const record of records) {
      const response = await api.moveRecord(record.id, data)
      responses.push(response)
    }
    this.isLoading = false
    const responseError = _.find(responses, { ok: false })
    if (!responseError) {
      showMessage(i18n.t('photoSMoved', { count: records.length, name }))
      // routerStore.push('/dashboard')
      return true
    } else {
      if (responseError.status === 403) {
        showError(i18n.t('youAreNotAllowedToMoveThisPhoto', { count: records.length }))
      } else {
        showResponseError(responseError)
      }

      return false
    }
  }

  @action async moveListRecordToTrash (records, name) {
    this.isLoading = true
    const responses = await Promise.all(
      records.map(record => {
        return api.moveRecordToTrash(record.id)
      }),
    )
    this.isLoading = false
    const responseError = _.find(responses, { ok: false })
    if (!responseError) {
      showMessage(i18n.t('photoSMovedToTrash', { count: records.length, name }))
      this.records = this.records.filter(r1 => !records.find(r2 => r2.id === r1.id))
      // routerStore.push('/dashboard')
    } else {
      showResponseError(responseError)
    }
  }

  @action async downloadListRecord (records, name, type) {
    // this.isDownloading = true
    // showMessage(i18n.t('theFileIsBeingDownloaded'))
    const responses = []
    for (const record of records) {
      if (_.isEmpty(record.series)) {
        const response = await api.downloadRecordMeta(record.id, type)
        responses.push(response)
      } else {
        const response = await Promise.all(
          record.series.map(recordSeries => {
            return api.downloadRecordMeta(record.id, type, recordSeries.id)
          }),
        )
        responses.push(...response)
      }
    }

    const responseError = _.find(responses, { ok: false })
    if (!responseError) {
      const zip = new JSZip()
      const time = moment.tz('Europe/Stockholm').format('YYYY-MM-DD')
      const fileName = `${name}-${time}`
      const folder = zip.folder(fileName)
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i]
        const fileName = `${i}.${type === 'pdf' ? 'pdf' : 'jpg'}`
        await folder.file(fileName, response.data)
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        // see FileSaver.js
        saveAs(content, fileName + '.zip')
      })
      // showMessage('Restore Record Success')
    } else {
      showResponseError(responseError)
    }
  }

  @action async rotate (record, rotate, index) {
    this.isLoading = true
    const seriesId = _.isEmpty(record.series) ? undefined : record.series[index].id
    const response = await api.rotate(record.id, {
      seriesId,
      rotate,
    })
    this.isLoading = false
    if (response.ok) {
      this.getRecord(record.id)
    } else {
      showResponseError(response)
    }
  }

  @action async uploadRecord (data, id, isProject, isSeries) {
    this.isLoading = true

    const headers = {
      'Content-Type': 'multipart/form-data',
    }

    if (data.images.length === 1) isSeries = false
    const uploads = []
    for (let i = 0; i < data.images.length; i++) {
      const formData = new FormData()
      const file = data.images[i].file
      data.images[i].uuid = uuid.v4()
      formData.append('uuid', data.images[i].uuid)
      formData.append('imgFile', file)
      uploads.push(
        api.uploadFile(formData, {
          headers,
          timeout: 10 * 60 * 1000,
        }),
      )
    }
    const responses = await Promise.all(uploads)
    const failed = responses.find(r => !r.ok)
    if (failed) {
      showResponseError(failed)
      return
    }
    if (isSeries) {
      const recordId = uuid.v4() // data.images[0].uuid
      const formData = {
        created: Moment().toISOString(),
        projectId: data.projectId,
        isSeries: '1',
        ReservedCount: data.images.length,
        series: data.images.map(image => ({
          uuid: image.uuid,
        })),
      }
      if (data.description) {
        formData.description = data.description
      }
      if (data.projectFolderId) {
        formData.projectFolderId = data.projectFolderId
      }
      const response = await api.uploadRecordMeta(recordId, formData)
      if (!response.ok) {
        showResponseError(response)
        return
      }
    } else {
      for (let index = 0; index < data.images.length; index++) {
        const recordId = uuid.v4()
        const formData = {
          uuid: data.images[index].uuid,
          created: Moment().toISOString(),
          projectId: data.projectId,
          isSeries: '0',
        }
        if (data.description) {
          formData.description = data.description
        }
        if (data.projectFolderId) {
          formData.projectFolderId = data.projectFolderId
        }

        const response = await api.uploadRecordMeta(recordId, formData)
        if (!response.ok) {
          showResponseError(response)
          return
        }
      }
    }
    this.setOffset(0)
    if (projectStore.id === id) {
      this.getListRecord(id, isProject)
    }
    this.isLoading = false
  }

  @action async uploadRecordSeries (id, data) {
    this.isLoading = true
    const formData = new FormData()
    formData.append('uuid', uuid.v4())
    formData.append('imgFile', data)
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const uploadResponse = await api.uploadRecordSeries(id, formData, {
      headers,
      timeout: 10 * 60 * 1000,
    })
    this.isLoading = false
    return uploadResponse
  }

  @action async getShareVideoRecord (id, token) {
    this.isLoading = true
    this.isDownloading = false
    const response = await api.getShareVideoRecord(id, token)
    this.isLoading = false
    if (response.ok) {
      this.shareVideoRecord = response.data
      return true
    } else {
      showResponseError(response)
      return false
    }
  }
}

const recordStore = new RecordStore()
export default recordStore

// Simple React Native specific changes

export default {
  // font scaling override - RN default is on
  allowTextFontScaling: true,
  baseURL: window.config.REACT_APP_BASE_URL,
  homeURL: window.config.REACT_APP_HOME_URL,
  uploadURL: window.config.REACT_APP_UPLOAD_URL,
  apiURL: window.config.REACT_APP_API_URL,
  googleApiKey: window.config.REACT_APP_GOOGLE_API_KEY,
  termsURL: window.config.REACT_APP_TERMS_URL,
  pricingURL: window.config.REACT_APP_PRICING_URL,
}

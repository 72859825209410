import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import api from '../services/ApiDefault'
import Loading from '../utilities/loading'
import routerStore from './RouterStore'
import { showMessage, showResponseError } from '../utilities/utils'
import authStore from './AuthStore'
import moment from 'moment-timezone'
import _ from 'lodash'
import i18n from '../i18n/i18n'

export class OrganizationStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @persist @observable organizations = []
  @observable currentOrganization = null
  @observable members = []

  @action setOrganizations (organizations) {
    this.organizations = organizations
  }

  @action setCurrentOrganization (organization) {
    this.currentOrganization = organization
    authStore.setCurrentOrganizationId(organization ? organization.orgId : null)
  }

  @action async getOrganizations () {
    this.isLoading = true
    const response = await api.getOrganizations()
    this.isLoading = false
    if (response.ok) {
      const organizations = _.orderBy(
        response.data.map(o => {
          return {
            ...o,
            currentRole:
              o.orgMembers.find(m => m.userId === authStore.user.id)?.orgMemberRole || '',
          }
        }),
        'organizationName',
      )
      let currentOrganization = organizations.find(o => o.orgId === authStore.currentOrganizationId)
      if (!currentOrganization) {
        currentOrganization = organizations.find(
          o =>
            o.subscription &&
            o.subscription.servicePlanId !== 'TrialFree' &&
            o.orgMembers.find(member => member.userId === authStore.user.id)?.orgMemberRole ===
              'Owner',
        )
      }
      if (!currentOrganization) {
        currentOrganization = organizations.find(
          o =>
            o.subscription &&
            o.subscription.servicePlanId !== 'TrialFree' &&
            o.orgMembers.find(member => member.userId === authStore.user.id),
        )
      }
      if (!currentOrganization) {
        currentOrganization = organizations.find(
          o => o.subscription && o.subscription.servicePlanId !== 'TrialFree',
        )
      }
      if (!currentOrganization) {
        currentOrganization = organizations.find(o => o.isPersonal)
      }
      this.setCurrentOrganization(currentOrganization)
      this.organizations = organizations
      // console.log(JSON.stringify(organizations, null, 2))
    } else {
      showResponseError(response)
    }
  }

  @action async getOrganization (id) {
    this.isLoading = true
    const response = await api.getOrganization(id)
    this.isLoading = false
    if (response.ok) {
      this.setCurrentOrganization(response.data)
    } else {
      showResponseError(response)
    }
  }

  @action async createOrganization (data) {
    this.isLoading = true
    const response = await api.createOrganization(data)
    this.isLoading = false
    if (response.ok) {
      // await this.getOrganizations()
      return response.data
      // return this.organization
      // showMessage(i18n.t('organizationCreated'))
      // routerStore.push(`/dashboard/organizations/${response.data.newOrganization.id}`)
    } else {
      showResponseError(response)
    }
  }

  @action async changeOrganizationOwner (id, toMemberId) {
    this.isLoading = true
    const response = await api.changeOrganizationOwner(id, toMemberId)
    this.isLoading = false
    if (response.ok) {
      return response.data
    } else {
      showResponseError(response)
    }
  }

  @action async getChangingOwnerRequest (id) {
    this.isLoading = true
    const response = await api.getChangingOwnerRequest(id)
    this.isLoading = false
    if (response.ok) {
      return response.data
    } else {
      showResponseError(response)
    }
  }
  @action async acceptOrganizationOwner (id, token) {
    this.isLoading = true
    const response = await api.acceptOrganizationOwner(id, token)
    this.isLoading = false
    if (response.ok) {
      return response.data
    } else {
      showResponseError(response)
    }
  }
  @action async revokeOrganizationOwner (id, token) {
    this.isLoading = true
    const response = await api.revokeOrganizationOwner(id, token)
    this.isLoading = false
    if (response.ok) {
      return response.data
    } else {
      showResponseError(response)
    }
  }

  @action async deleteOrganization (id) {
    this.isLoading = true
    const response = await api.deleteOrganization(id)
    this.isLoading = false
    if (response.ok) {
      // await this.getOrganizations()
      return response.data
      // return this.organization
      // showMessage(i18n.t('organizationCreated'))
      // routerStore.push(`/dashboard/organizations/${response.data.newOrganization.id}`)
    } else {
      showResponseError(response)
    }
  }

  @action async restoreOrganization (id) {
    this.isLoading = true
    const response = await api.restoreOrganization(id)
    this.isLoading = false
    if (response.ok) {
      showMessage('Your subscription was restored')
    } else {
      showResponseError(response)
    }
  }

  @action async getOrganizationMembers (id) {
    this.isLoading = true
    const response = await api.getOrganizationMembers(id)
    this.isLoading = false
    if (response.ok) {
      this.members = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async updateOrganization (id, organization) {
    this.isLoading = true
    const response = await api.updateOrganization(id, organization)
    if (response.ok) {
      // await this.getOrganizations()
      // routerStore.push('/organizations/settings')
      showMessage(i18n.t('organizationUpdated'))
      return response.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async addMembers (organization, members) {
    this.isLoading = true
    for (const member of members) {
      const responseMember = await api.addOrganizationMember(organization.orgId, member)
      if (!responseMember.ok) {
        showResponseError(responseMember)
        return false
      }
    }

    // await this.getOrganization(organization.orgId)
    await this.getOrganizations()
    // showMessage(i18n.t('projectUpdated'))
    this.isLoading = false
    return true
  }

  @action async removeMember (organization, member) {
    this.isLoading = true
    const response = await api.removeOrganizationMember(organization.orgId, member.orgMemberId)
    if (response.ok) {
      // await this.getOrganization(organization.orgId)
      await this.getOrganizations()
      this.isLoading = false
      return true
    } else {
      this.isLoading = false
      showResponseError(response)
      return false
    }
  }

  @action async updateMember (organization, member) {
    this.isLoading = true
    const response = await api.updateOrganizationMember(organization.orgId, member)
    if (response.ok) {
      // await this.getOrganization(organization.orgId)
      await this.getOrganizations()
      this.isLoading = false
      return true
    } else {
      this.isLoading = false
      showResponseError(response)
      return false
    }
  }

  @action async sendInvite (data) {
    this.isLoading = true
    const response = await api.sendInvite(data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('sendSuccess'))
    } else {
      showResponseError(response)
    }
  }
}

const organizationStore = new OrganizationStore()
export default organizationStore

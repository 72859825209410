import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { Button, Container, Navbar, NavbarBrand } from 'reactstrap'
import './ChangeOwnerRevoke.scss'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import AppConfig from '../../config/AppConfig'
import Loading from '../../components/Loading/Loading'

@withRouter
@withTranslation()
@inject('organizationStore', 'routerStore')
@observer
class ChangeOwnerRevoke extends Component {
  accept = async () => {
    const orgId = this.props.match.params.orgId
    const token = this.props.match.params.token
    console.log(orgId, token)
    const result = await this.props.organizationStore.acceptOrganizationOwner(orgId, token)
    if (result) {
      console.log(result)
    }
    this.props.routerStore.replace('/')
  }

  revoke = async () => {
    const orgId = this.props.match.params.orgId
    const token = this.props.match.params.token
    console.log(orgId, token)
    const result = await this.props.organizationStore.revokeOrganizationOwner(orgId, token)
    if (result) {
      console.log(result)
    }
    this.props.routerStore.replace('/')
  }

  render () {
    const { t } = this.props
    if (this.props.authStore.isStarting) {
      return <Loading />
    }
    if (!this.props.authStore.token) {
      return <Redirect from='*' to={`/login?redirect=${this.props.location.pathname}`} />
    }
    return (
      <div>
        <Container>
          <div className='align-items-center app pt-10' style={{ marginTop: 20 }}>
            <img src={require('../../assets/images/logo.png')} className='logo' />
            <h3>{t('youSentARequestToChangeOrganizationOwner')}!.</h3>
            <div className='d-flex text-center mt-10'>
              <Button color='danger' size='md' onClick={this.revoke}>
                {t('revoke')}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default ChangeOwnerRevoke

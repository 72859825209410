import { observable, action, toJS } from 'mobx'
import api from '../services/ApiDefault'
import routerStore from './RouterStore'
import { showError, showMessage, showResponseError } from '../utilities/utils'
import { saveAs } from 'file-saver'
import moment from 'moment-timezone'
import i18n from '../i18n/i18n'
import _ from 'lodash'

export class ShareStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @observable shareLinks = []
  @observable shareLink = null
  @observable record = null
  @observable records = []
  @observable offset = 0
  @observable limit = 20
  @observable changeListRecord = false

  @action setOffset (offset) {
    this.offset = offset
    if (offset === 0) {
      this.records = []
    }
  }

  @action setLimit (limit) {
    this.limit = limit
  }

  @action setRecord (record) {
    this.record = record
  }

  @action async getShareLinks () {
    this.isLoading = true

    const response = await api.getShareLinks()
    this.isLoading = false
    if (response.ok) {
      this.shareLinks = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async createShareLink (data) {
    this.isLoading = true
    const response = await api.createShareLink(data)
    this.isLoading = false
    if (response.ok) {
      await this.getShareLinks()
    } else {
      showResponseError(response)
    }
  }

  @action async getShareLink (id) {
    this.isLoading = true

    const response = await api.getShareLink(id)
    this.isLoading = false
    if (response.ok) {
      this.shareLink = response.data
    } else {
      if (response.status < 500) {
        showError(i18n.t('theLinkDoesNotExist'))
      } else {
        showResponseError(response)
      }
      routerStore.replace('/404')
    }
  }

  @action async deleteShareLinks (shareLinks) {
    this.isLoading = true
    const responses = await Promise.all(
      shareLinks.map(shareLink => api.deleteShareLink(shareLink.shareId)),
    )
    this.isLoading = false
    if (!_.find(responses, response => !response.ok)) {
      await this.getShareLinks()
      showMessage(i18n.t('allGuestsCanceled'))
    } else {
      showResponseError(_.find(responses, response => !response.ok))
    }
  }

  @action async getShareRecords (id, folderId) {
    const offset = this.offset
    this.isLoading = true

    let response = null
    if (folderId) {
      response = await api.getShareFolderRecords(id, folderId, {
        disable: false,
        offset,
        limit: this.limit,
      })
    } else {
      response = await api.getShareRecords(id, {
        disable: false,
        offset,
        limit: this.limit,
        ...this.paramRecord,
      })
    }

    this.isLoading = false
    if (response.ok) {
      if (offset === 0) {
        this.records = response.data
      } else {
        this.records = _.concat(toJS(this.records), response.data)
      }
    } else {
      showResponseError(response)
    }
  }

  @action async downloadShareRecord (shareId, record, type, index) {
    // this.isDownloading = true
    // showMessage(i18n.t('theFileIsBeingDownloaded'))
    let response = null
    const seriesId = _.isEmpty(record.series) ? undefined : record.series[index].id
    if (type === 'pdf') {
      response = await api.downloadShareRecordMeta(shareId, record.id, type, seriesId)
    } else if (type === 'photo') {
      response = await api.downloadShareRecordMeta(shareId, record.id, type, seriesId)
    } else {
      response = await api.downloadRecord(record.id, seriesId)
    }

    // this.isDownloading = false
    if (response.ok) {
      const time = moment(record.created)
        .tz('Europe/Stockholm')
        .format('YYYY-MM-DD-HH-ss')
      const names = [record.projectName]
      if (record.projectFolderName) {
        names.push(record.projectFolderName)
      }
      if (seriesId) {
        names.push(record.series[index].serialNumber)
      } else {
        names.push(record.serial_number)
      }
      if (record.description) {
        names.push(record.description.substring(0, 80))
      }
      names.push(time)
      const replaceItems = { å: 'a', ä: 'a', ö: 'o' }
      const fileName =
        _.compact(names)
          .join('-')
          .replace(/[å,ä,ö]/, m => replaceItems[m]) + (type === 'pdf' ? '.pdf' : '.jpg')
      saveAs(response.data, fileName)
      // showMessage('Restore Record Success')
    } else {
      showResponseError(response)
    }
  }
}

const shareStore = new ShareStore()
export default shareStore

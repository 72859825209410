import { observable, action, toJS } from 'mobx'
import api from '../services/ApiDefault'
import { showMessage, showResponseError } from '../utilities/utils'
import routerStore from './RouterStore'

export class UserStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @observable users = []
  @observable searchUsers = []
  @observable organizations = []
  @observable currentAdminOrganization = null
  @observable total = 0
  @observable pageNumber = 0
  @observable lastPage = null
  @observable pageSize = 20
  @observable orgTotal = 0
  @observable orgPageNumber = 0
  @observable orgLastPage = null
  @observable orgPageSize = 20

  @action setSearchUsers (searchUsers) {
    this.searchUsers = searchUsers
  }

  @action setCurrentAdminOrganization (currentAdminOrganization) {
    this.currentAdminOrganization = currentAdminOrganization
  }

  @action async getUsers ({ pageNumber, search }) {
    this.isLoading = true
    const response = await api.getUsers({
      pageNumber,
      pageSize: 20,
      search,
    })

    if (response.ok) {
      this.users = response.data.users
      this.pageNumber = response.data.pageNumber
      this.total = response.data.total
      this.lastPage = response.data.lastPage
      this.pageSize = response.data.pageSize
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getAdminOrganizations (params) {
    this.organizations = []
    this.isLoading = true
    const response = await api.getAdminOrganizations(params)
    this.isLoading = false
    if (response.ok) {
      this.organizations = response.data.orgList
      this.orgPageNumber = response.data.pageNumber
      this.orgPageSize = response.data.pageSize
      this.orgPastPage = response.data.lastPage
      this.orgTotal = response.data.totalCount
    } else {
      showResponseError(response)
    }
  }

  @action async searchUser (search) {
    this.isLoading = true
    const response = await api.searchUsers(search)
    this.isLoading = false
    if (response.ok) {
      this.searchUsers = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async createUser (data) {
    this.isLoading = true
    const response = await api.createUser(data)
    this.isLoading = false
    if (response.ok) {
      routerStore.push('/dashboard/users')
    } else {
      showResponseError(response)
    }
  }

  @action async getValidationLink (user) {
    this.isLoading = true
    const response = await api.getValidationLink({ email: user.email, culture: user.culture })
    this.isLoading = false
    if (response.ok) {
      return response.data.validationLink
    } else {
      showResponseError(response)
      return false
    }
  }

  @action async deleteUser (id) {
    this.isLoading = true
    const response = await api.deleteUser(id)
    this.isLoading = false
    if (response.ok) {
      showMessage('User account was deleted successfully!')
      return true
    } else {
      showResponseError(response)
      return false
    }
  }
}

const userStore = new UserStore()
export default userStore

import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import api from '../services/ApiDefault'
import Loading from '../utilities/loading'
import routerStore from './RouterStore'
import { showError, showMessage, showResponseError } from '../utilities/utils'
import authStore from './AuthStore'
import organizationStore from './OrganizationStore'
import moment from 'moment-timezone'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import i18n from '../i18n/i18n'

export class ProjectStore {
  // @desc this field is not persisted
  @observable isLoading = false
  @persist @observable projects = []
  @persist @observable projectsTrash = []
  @persist @observable foldersTrash = []
  @persist @observable project = {}
  @persist @observable folder = {}
  @observable members = []
  @observable copyingMembers = []
  @observable id = null
  @observable edit = false
  @observable deleteId = null
  @observable deleteFolderId = null
  @observable setTimeDelete = null
  @observable setTimeFolderDelete = null
  @observable isDownloading = false
  @observable projectSelect = {}
  @observable projectState = 1
  @observable projectPage = 0
  @observable projectListEnd = false
  @observable filterProject = ''

  @action setFilterProject(filterProject) {
    this.filterProject = filterProject
  }

  @action setProjects(projects) {
    this.projects = projects
  }

  @action setProjectSelect(project) {
    this.projectSelect = project
  }

  @action setProject(project) {
    this.project = project
  }

  @action setId(id) {
    this.id = id
  }

  @action setEdit(edit) {
    this.edit = edit
  }

  @action setDeleteId(id) {
    this.deleteId = id
  }

  @action setDeleteFolderId(id) {
    this.deleteFolderId = id
  }

  @action setFolder(folder) {
    this.folder = folder
  }

  @action async getProjects(projectState, page) {
    if (projectState !== undefined) this.projectState = projectState
    this.isLoading = true
    if (page !== undefined) {
      this.projectPage = page
    }
    const params = {
      disable: false,
      projectState: this.projectState,
      page_number: this.projectPage,
      page_size: 100,
      keyword: this.filterProject,
    }
    const currentOrganization = organizationStore.currentOrganization
    if (currentOrganization && !currentOrganization.isPersonal) {
      params.orgId = currentOrganization.orgId
    } else if (currentOrganization) {
      params.personal = true
    }

    const response = await api.getProjects(params)
    this.isLoading = false
    if (response.ok) {
      this.projects = response.data
      if (response.data.length < 100) {
        this.projectListEnd = true
      } else {
        this.projectListEnd = false
      }
    } else {
      showResponseError(response)
    }
  }

  @action async getProjectsTrash() {
    this.isLoading = true

    const currentOrganization = organizationStore.currentOrganization
    if (!currentOrganization) return
    const response = await api.getProjects({
      disable: true,
      orgId: currentOrganization ? currentOrganization.orgId : null,
    })
    this.isLoading = false

    if (response.ok) {
      this.projectsTrash = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async getProjectFoldersTrash() {
    this.isLoading = true

    const currentOrganization = organizationStore.currentOrganization
    const response = await api.getProjectFolders({
      disable: true,
      orgId: currentOrganization ? currentOrganization.orgId : null,
    })
    this.isLoading = false
    if (response.ok) {
      this.foldersTrash = response.data
    } else {
      showResponseError(response)
    }
  }

  @action async getProject(id) {
    this.isLoading = true

    const response = await api.getProject(id)
    this.isLoading = false
    if (response.ok) {
      if (response.data.disabled) {
        this.getProjects()
        showError(i18n.t('theProjectWasDeletedOrDoesNotExist'))
        this.project = {}
        this.folder = {}
        authStore.setCurrentFolderId(null)
        authStore.setCurrentProjectId(null)
        routerStore.push('/dashboard')
        return false
      } else {
        this.getProjects()
        this.project = response.data
        authStore.setCurrentProjectId(this.project.id)

        this.edit = true
        return true
      }
    } else {
      this.getProjects()
      // if (response.problem === 'CLIENT_ERROR')
      //   showError(i18n.t('theProjectWasDeletedOrDoesNotExist'))
      // else
      this.project = {}
      this.folder = {}
      authStore.setCurrentFolderId(null)
      authStore.setCurrentProjectId(null)

      showResponseError(response)
      routerStore.push('/dashboard')
      return false
    }
  }

  @action async createProject(data, members) {
    this.isLoading = true
    if (organizationStore.currentOrganization) {
      data.orgId = organizationStore.currentOrganization.orgId
    }
    const response = await api.createProject(data)
    if (response.ok) {
      if (!_.isEmpty(members)) {
        const responseMember = await api.addProjectMember(response.data.projDto.id, members)
        if (!responseMember.ok) {
          showResponseError(responseMember)
          return false
        }
      }
      await this.getProjects()
      showMessage(i18n.t('projectCreated'))
      routerStore.push(`/dashboard/projects/${response.data.projDto.id}`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getProjectMembers(id, showError = true) {
    this.isLoading = true

    const response = await api.getProjectMembers(id)
    this.isLoading = false
    if (response.ok) {
      this.members = response.data
    } else {
      showError && showResponseError(response)
    }
  }

  @action async getCopyingProjectMembers(id, showError = true) {
    this.isLoading = true

    const response = await api.getProjectMembers(id)
    this.isLoading = false
    if (response.ok) {
      this.copyingMembers = response.data
      return this.copyingMembers
    } else {
      showError && showResponseError(response)
    }
  }

  @action async updateProject(project, members, folders, projectFolders) {
    this.isLoading = true

    project.folders = _.concat(folders, projectFolders)
    const response = await api.editProject(project.id, project)
    if (response.ok) {
      if (!_.isEmpty(members)) {
        const responseMember = await api.addProjectMember(project.id, members)
        if (!responseMember.ok) {
          showResponseError(responseMember)
          return false
        }
      }

      await this.getProjects()
      routerStore.push(`/dashboard/projects/${project.id}`)
      showMessage(i18n.t('projectUpdated'))
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async toggleArchive(project) {
    this.isLoading = true
    project.projectState = project.projectState === 2 ? 1 : 2

    const response = await api.editProject(project.id, project)

    if (response.ok) {
      this.getProjects()
      routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateProjectMemberRole(projectId, id, role) {
    this.isLoading = true
    const response = await api.updateProjectMemberRole(projectId, id, {
      projectMemberRole: role,
    })
    this.isLoading = false
    if (response.ok) {
    } else {
      showResponseError(response)
    }
  }

  @action async createRegistrationLink(id) {
    this.isLoading = true
    const response = await api.createRegistrationLink(id)
    this.isLoading = false
    if (response.ok) {
      return response.data.inviteLink
    } else {
      showResponseError(response)
    }
  }

  @action async joinProject(id, resetKey) {
    this.isLoading = true
    const response = await api.joinProject(id, { resetKey })
    this.isLoading = false
    if (response.ok) {
      this.getProjects(undefined, 0)
      return true
    } else {
      showResponseError(response)
    }
  }

  @action async acceptProjectMember(id, memberId) {
    this.isLoading = true
    const response = await api.acceptProjectMember(id, memberId)
    this.isLoading = false
    if (response.ok) {
      this.getProjectMembers(id)
    } else {
      showResponseError(response)
    }
  }

  @action async removeProjectMember(id, memberId) {
    this.isLoading = true

    const response = await api.removeProjectMember(id, memberId)
    this.isLoading = false
    if (response.ok) {
      this.getProjectMembers(id)
    } else {
      showResponseError(response)
    }
  }

  @action async moveProjectToTrash(id) {
    if (this.setTimeDelete) {
      clearTimeout(this.setTimeDelete)
    }
    this.isLoading = true

    const response = await api.moveProjectToTrash(id)
    this.isLoading = false
    if (response.ok) {
      this.deleteId = id
      this.project = {}
      this.folder = {}
      authStore.setCurrentFolderId(null)
      authStore.setCurrentProjectId(null)

      this.getProjects()
      this.setTimeDelete = setTimeout(() => {
        this.deleteId = null
      }, 10000)
      routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
  }

  @action async deleteProject(id) {
    this.isLoading = true

    const response = await api.deleteProject(id)
    this.isLoading = false
    if (response.ok) {
      this.deleteId = id
      showMessage(i18n.t('projectErased'))
      routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
  }

  @action async deleteFolder(id) {
    this.isLoading = true

    const response = await api.deleteFolder(id)
    this.isLoading = false
    if (response.ok) {
      this.deleteId = id
      showMessage(i18n.t('folderErased'))
      routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
  }

  @action async getProjectFolder(id) {
    this.isLoading = true

    const response = await api.getProjectFolder(id)
    this.isLoading = false
    if (response.ok) {
      if (response.data.disabled) {
        this.folder = {}
        this.project = {}
        authStore.setCurrentFolderId(null)
        authStore.setCurrentProjectId(null)

        showError(i18n.t('theProjectWasDeletedOrDoesNotExist'))
        routerStore.push('/dashboard')
        this.getProjects()
        return false
      } else {
        this.folder = response.data
        authStore.setCurrentFolderId(this.folder.id)
        await this.getProjects()
        this.project = this.projects.find(p => p.id === this.folder.projectId)

        return true
      }
    } else {
      this.getProjects()
      // if (response.problem === 'CLIENT_ERROR')
      //   showError(i18n.t('theProjectWasDeletedOrDoesNotExist'))
      // else
      showResponseError(response)
      return false
    }
  }

  @action async createFolder(data) {
    this.isLoading = true

    const response = await api.createProjectFolder(data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('folderCreated'))
      this.getProjects()
    } else {
      showResponseError(response)
    }
  }

  @action async sendInvite(data) {
    this.isLoading = true

    const response = await api.sendInvite(data)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('sendSuccess'))
    } else {
      showResponseError(response)
    }
  }

  @action async editFolder(id, data) {
    this.isLoading = true

    const response = await api.editProjectFolder(id, data)
    this.isLoading = false
    if (response.ok) {
      this.getProjectFolder(id)
      this.getProjects()
      showMessage(i18n.t('folderUpdated'))
    } else {
      showResponseError(response)
    }
  }

  @action async restoreProject(id) {
    this.isLoading = true

    const response = await api.restoreProject(id)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('projectRestored'))
      this.deleteId = null
      this.getProjects()
      this.getProjectsTrash()
    } else {
      showResponseError(response)
    }
  }

  @action async moveFolderToTrash(id, redirect = true) {
    if (this.setTimeFolderDelete) {
      clearTimeout(this.setTimeFolderDelete)
    }
    this.isLoading = true

    const response = await api.moveFolderToTrash(id)
    this.isLoading = false
    if (response.ok) {
      this.deleteFolderId = id
      this.folder = {}
      authStore.setCurrentFolderId(null)
      authStore.setCurrentProjectId(null)
      this.getProjects()
      this.setTimeFolderDelete = setTimeout(() => {
        this.deleteFolderId = null
      }, 10000)
      if (!redirect) {
        return true
      }
      routerStore.push('/dashboard')
    } else {
      showResponseError(response)
    }
  }

  @action async restoreFolder(id) {
    this.isLoading = true

    const response = await api.restoreFolder(id)
    this.isLoading = false
    if (response.ok) {
      showMessage(i18n.t('folderRestored'))
      this.deleteFolderId = null
      this.getProjects()
      this.getProjectFoldersTrash()
    } else {
      showResponseError(response)
    }
  }

  @action async clearNotifications() {
    this.isLoading = true

    const response = await api.clearNotifications()
    this.isLoading = false
    if (response.ok) {
      this.projects = this.projects.map(p => {
        p.unreadCount = 0
        p.listFolder = (p.listFolder || []).map(f => {
          f.unreadCount = 0
          return f
        })
        return p
      })
    } else {
      showResponseError(response)
    }
  }

  @action async exportProject(project) {
    if (this.isDownloading) return false
    this.isDownloading = true
    const response = await api.exportProject(
      project.projectId ? project.projectId : project.id,
      project.projectId && project.id,
    )
    this.isDownloading = false
    if (response.ok) {
      const time = moment.tz('Europe/Stockholm').format('YYYY-MM-DD_HH_mm_ss')
      const replaceItems = { å: 'a', ä: 'a', ö: 'o' }
      const fileName = `${project.name}_${time}.xlsx`.replace(/[å,ä,ö]/, m => replaceItems[m])
      saveAs(response.data, fileName.replace(/\s/g, '_'))
      // showMessage('Restore Record Success')
    } else {
      showResponseError(response)
    }
  }

  @action async downloadProject(project) {
    if (this.isDownloading) return false
    this.isDownloading = true
    const response = await api.downloadProject(project.id)
    this.isDownloading = false
    if (response.ok) {
      showMessage(
        i18n.t('aDownloadLinkHasBeenSentToYourEMailAddress', {
          email: authStore.user.email,
        }),
      )
    } else {
      showResponseError(response)
    }
  }

  @action async downloadRecordMetaZip(recordIds, type, immediateLink) {
    if (this.isDownloading) return false
    // showMessage(i18n.t('theFileIsBeingDownloaded'))
    this.isDownloading = true
    const response = await api.downloadRecordMetaZip(recordIds, type, immediateLink)
    this.isDownloading = false
    if (response.ok) {
      if (response.data?.downloadUrl) {
        if (response.data?.downloadUrl.includes('.pdf')) {
          // const downloadResponse = await api.downloadFile(response.data?.downloadUrl)
          // if (downloadResponse.ok) {
          //   saveAs(downloadResponse.data, _.last(response.data?.downloadUrl.split('/')))
          // }
          window.location.href = response.data.downloadUrl
        } else {
          window.location.href = response.data.downloadUrl
        }
        // window.open(response.data.downloadUrl, '_blank')
      } else {
        showMessage(
          i18n.t('aDownloadLinkHasBeenSentToYourEMailAddress', {
            email: authStore.user.email,
          }),
        )
      }
    } else {
      showResponseError(response)
    }
  }

  @action async shareRecordMetaZip(recordIds, data) {
    if (this.isDownloading) return false
    this.isDownloading = true
    const response = await api.shareRecordMetaZip(recordIds, data)
    this.isDownloading = false
    if (response.ok) {
      showMessage(i18n.t('aDownloadLinkHasBeenSentTo', { email: data.emails.join(',') }))
      return true
    } else {
      showResponseError(response)
      return false
    }
  }
}

const projectStore = new ProjectStore()
export default projectStore

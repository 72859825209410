import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError, showMessage } from '../utilities/utils'
import routerStore from './RouterStore'
import Loading from '../utilities/loading'
import { persist } from 'mobx-persist'
import projectStore from './ProjectStore'
import organizationStore from './OrganizationStore'
import i18n from '../i18n/i18n'
import moment from 'moment-timezone'
import Moment from 'moment'
import { saveAs } from 'file-saver'
import AppConfig from '../config/AppConfig'
import Validator from 'validatorjs'
import jwtDecode from 'jwt-decode'

export class AuthStore {
  // @desc this field is not persisted
  // @ignore
  @observable isLoading = false
  @observable isStarting = true
  @observable isOpenSide = false
  @observable reset = false
  @observable registerSuccess = false

  @persist @observable token = null
  @persist @observable userId = null
  @observable user = null
  @persist @observable refreshToken = null
  @persist @observable skipWelcome = false
  @persist @observable keepLogin = false
  @persist @observable language = 'sv'
  @persist @observable currentOrganizationId = null
  @persist @observable currentProjectId = null
  @persist @observable currentFolderId = null

  @action async setLanguage (lang) {
    console.log('change language:', lang)
    Validator.useLang(lang)
    Moment.locale(lang)
    moment.localeData(language)
    const language = lang === 'sv' ? 'sv-SE' : 'en-US'
    i18n.changeLanguage(language)
    api.setLanguage(language)
    if (this.language === lang) return
    this.language = lang
    if (this.user && this.user.culture !== language) {
      await api.changeLanguage({ culture: language })
      await this.getCurrentUser()
    }
  }

  @action setCurrentOrganizationId (organizationId) {
    this.currentOrganizationId = organizationId
  }
  @action setCurrentProjectId (projectId) {
    this.currentProjectId = projectId
  }
  @action setCurrentFolderId (folderId) {
    this.currentFolderId = folderId
  }

  @action setResetPassword (reset) {
    this.reset = reset
  }

  @action toggleAppSide () {
    this.isOpenSide = !this.isOpenSide
  }

  @action setRegisterSuccess (registerSuccess) {
    this.registerSuccess = registerSuccess
  }

  @action setKeepLogin (keepLogin) {
    this.keepLogin = keepLogin
  }

  @action async checkLogged () {
    i18n.changeLanguage(this.language)
    api.setLanguage(this.language === 'sv' ? 'sv-SE' : 'en-US')
    if (this.token && this.userId) {
      api.setToken(this.token)
      await this.getCurrentUser()
      if (this.user.disabled && routerStore.location.pathname.startsWith('/dashboard')) {
        this.isStarting = false
        routerStore.replace('/login')
        return
      }
      await organizationStore.getOrganizations()
      this.isStarting = false
      await projectStore.getProjects()
    } else {
      if (routerStore.location.pathname.startsWith('/dashboard')) {
        routerStore.replace('/login')
      }
      this.isStarting = false
    }
    Loading.hide()
  }

  @action async getCurrentUser () {
    const response = await api.getUser(this.userId)
    if (response.ok) {
      this.user = response.data
      if (this.user.culture && this.user.culture.substring(0, 2) !== this.language) {
        this.setLanguage(this.user.culture === 'en-US' ? 'en' : 'sv')
      }
      return this.user
    } else {
      if (response.status === 401) {
        if (routerStore.location.pathname.startsWith('/dashboard')) {
          routerStore.replace('/login')
        }
      } else {
        showResponseError(response)
      }
    }
  }

  @action async login ({ email, password, redirect }) {
    this.isLoading = true

    const response = await api.login({ email, password })
    if (response.ok) {
      if (response.data.user && response.data.token) {
        this.userId = response.data.user.id
        this.token = response.data.token
        // this.user = response.data.user
        this.refreshToken = response.data.refreshToken
        api.setToken(response.data.token)
        // await this.getCurrentUser()

        await this.getCurrentUser()
        routerStore.replace(redirect || '/dashboard')
        if (!this.user.fistName && !this.user.lastName) {
          routerStore.replace('/dashboard/profile')
          showMessage(i18n.t('pleaseCompleteYourAccountInformationWithYourFirstAndFamilyName'))
        }
        await organizationStore.getOrganizations()
        await projectStore.getProjects()
      } else if (response.data.code === 'ACTIVATE_EMAIL') {
        // showError('Organization is not validated', response.data.message)
        this.isLoading = false
        return response.data.code
      } else {
        showResponseError(response)
      }
    } else {
      if (response.data && response.data.code === 'INVALID') {
        showError(i18n.t('emailOrPasswordIsIncorrect'))
      } else {
        showResponseError(response)
      }
    }

    this.isLoading = false
  }

  @action logout () {
    routerStore.replace('/logout')
    projectStore.setProjects([])
    organizationStore.setOrganizations([])
    organizationStore.setCurrentOrganization(null)
    this.token = null
    this.user = null
    this.userId = null
    this.currentFolderId = null
    this.currentOrganizationId = null
    this.currentProjectId = null
    setTimeout(() => {
      window.location.replace('/')
    }, 1000)
  }

  @action async sendValidationEmail (email) {
    this.isLoading = true
    const lang = this.language
    const response = await api.sendValidationEmail({ email, lang })

    if (response.ok) {
      showMessage(i18n.t('resendEmailMessage'))
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async register (data) {
    this.isLoading = true
    data.lang = this.language

    const response = await api.register(data)

    if (response.ok) {
      this.registerSuccess = true
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateUser (user) {
    this.isLoading = true

    const response = await api.updateUser({
      fistName: user.firstName,
      lastName: user.lastName,
      email: this.user.email,
    })

    if (response.ok) {
      if (user.password !== '') {
        this.changePassword({
          password: user.currentPassword,
          new_password: user.password,
        })
      } else {
        routerStore.replace('/dashboard')
      }
      await this.getCurrentUser()
      // this.user = response.data.data
      // this.isLoading = false
      // return true
      showMessage(i18n.t('updateProfileSuccess'))
    } else {
      showResponseError(response)
      return false
    }
    this.isLoading = false
  }

  @action async changePassword (data) {
    this.isLoading = true

    const response = await api.changePassword({
      Password: data.password,
      NewPassword: data.new_password,
    })

    if (response.ok) {
      showMessage(i18n.t('changePasswordSuccess'))
      routerStore.replace('/dashboard')
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async resetPassword (email) {
    this.isLoading = true

    const response = await api.resetPassword(email)

    if (response.ok) {
      this.reset = true
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async setPassword (data) {
    this.isLoading = true

    const response = await api.setPassword(data)

    if (response.ok) {
      showMessage(i18n.t('passwordChanged'))
      // routerStore.replace('/login')
      await this.login({
        email: data.email,
        password: data.newPassword,
      })
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async validateRegister (key) {
    this.isLoading = true

    const response = await api.validateRegister(key)

    if (response.ok) {
      this.registerSuccess = true
      const { token } = response.data
      const data = jwtDecode(token)
      this.token = token
      this.userId = data.ID
      api.setToken(token)
      await this.getCurrentUser()
      this.isLoading = false
      return response.data
    } else {
      showResponseError(response)
      this.isLoading = false
    }
  }

  @action async downloadListUser () {
    if (this.isDownloading) return false
    this.isDownloading = true
    const response = await api.downloadListUser()
    this.isDownloading = false
    if (response.ok) {
      const time = moment.tz('Europe/Stockholm').format('YYYY-MM-DD_HH_mm_ss')
      const fileName = `list_user_${time}.xlsx`
      saveAs(response.data, fileName.replace(/\s/g, '_'))
      // showMessage('Restore Record Success')
    } else {
      showResponseError(response)
    }
  }
}

const authStore = new AuthStore()
export default authStore

class Loading {
  static setComponent (comp) {
    this.comp = comp
  }

  static setSpinner (spinner) {
    this.spinner = spinner
  }

  static show () {
    this.comp && this.comp.complete()
    this.comp && this.comp.continousStart()
    this.spinner && this.spinner.complete()
    this.spinner && this.spinner.continousStart()
  }

  static hide () {
    setTimeout(() => {
      this.comp && this.comp.complete()
      this.spinner && this.spinner.complete()
    }, 500)
  }
}

export default Loading

import { NProgress } from '@tanem/react-nprogress'
import React from 'react'
import ReactDOM from 'react-dom'
import Bar from './Bar'
import Container from './Container'
import './style.css'
// import Spinner from './Spinner'

export default class LoadingBar extends React.Component {
  state = {
    isLoading: true,
  }

  continousStart () {
    this.setState({ isLoading: true })
  }

  complete () {
    this.setState({ isLoading: false })
  }

  componentDidMount () {
    this.complete()
  }

  render () {
    return (
      <NProgress isAnimating={this.state.isLoading}>
        {({ isFinished, progress, animationDuration }) => (
          <Container isFinished={isFinished} animationDuration={animationDuration}>
            <Bar progress={progress} animationDuration={animationDuration} />
            {/* <Spinner /> */}
          </Container>
        )}
      </NProgress>
    )
  }
}
